.App {
  font-family: sans-serif;
  max-width: 900px;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.App div {
  margin-bottom: 10px;
}

.App label {
  display: block;
  margin-bottom: 5px;
}
.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}


.App input[type="number"],
.App input[type="text"],
.App select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
}

.App button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.App button:hover {
  background-color: #0056b3;
}

.select-line {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Réduction de l'espacement pour mieux ajuster */
  margin-bottom: 10px;
}

.select-line > .input-group {
  flex: 1; /* Chaque élément prend une part égale de l'espace */
}

.select-line .input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8em; /* Réduction de la taille de la police des labels */
}

.select-line .input-group input[type="number"],
.select-line .input-group select {
  width: 100%;
  padding: 6px; /* Réduction du padding des inputs et selects */
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 0.7em; /* Réduction de la taille de la police des inputs et selects */
}

.indice-bar-container {
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.indice-bar {
  width: 100%;
  height: 40px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.zone {
  height: 100%;
  color: white;
  text-align: center;
  line-height: 30px;
  font-size: 0.9em;
  box-sizing: border-box;
  flex-grow: 1;
}

.faible {
  background-color: yellow;
  color: black;
}

.intermediaire {
  background-color: orange;
}

.forte {
  background-color: red;
}

.threshold {
  position: absolute;
  top: -25px;
  font-size: 0.8em;
  color: #555;
}

.result-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: black;
}

.info-irdp {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.info-irdp h3 {
  margin-top: 0;
}

.score-container {
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  text-align: center;
  margin-top:10px;
}

.score-value {
  font-size: 2em;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.formule-irdp {
  margin-top: 30px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 0.9em;
  line-height: 1.4;
}

.formule-irdp h3 {
  margin-top: 0;
}

.title-container {
  display: flex;
  align-items: center; /* Aligner verticalement le titre et l'image */
  justify-content: flex-start; /* Aligner le contenu à gauche */
  margin-bottom: 0px; /* Ajouter un peu d'espace en dessous du titre */
}

.irdp-logo {
  height: 50px; /* Ajustez la hauteur de l'image selon vos besoins */
  margin-left: 80%; /* Pousser l'image vers la droite */
  margin-top: -100%
}